import { updateServiceConsent } from '@store/service-consent';

type UCEventServiceName = 'Vimeo' | 'YouTube Video' | 'Mapbox';

type UCEventServiceStatus = Record<UCEventServiceName, boolean>;
type UCEventBaseDetail = {
    action: string;
    event: string;
    type: string;
    ucCategory: Record<string, boolean>;
};

type UCWindowEventDetail = UCEventBaseDetail & UCEventServiceStatus;

type UCEvent = CustomEvent<UCWindowEventDetail>;

const USERCENTRICS_WINDOW_EVENT = 'usercentrics::window-event';
const USERCENTRICS_CONSENT_CHANGE_EVENT = 'consent_status';

const createServiceConsentTracker = () => {
    const mapToStore = ({
        Vimeo: vimeo,
        ['YouTube Video']: youtube,
        Mapbox: mapbox,
    }: UCWindowEventDetail) => {
        updateServiceConsent({ vimeo, mapbox, youtube });
    };

    const init = () => {
        window.addEventListener(USERCENTRICS_WINDOW_EVENT, ({ detail }: UCEvent) => {
            if (detail?.event == USERCENTRICS_CONSENT_CHANGE_EVENT) {
                mapToStore(detail);
            }
        });
    };

    return { init };
};

export const serviceConsentTracker = createServiceConsentTracker();
