import { inviewObserver } from '@utilities/inview';

import { BPDVideoProps } from './video';

const VIDEO_HOOK = '[js-hook-video]';

/**
 * Basic inview controller that triggers an inview class based upon if an element is or out of view.
 */
export const videoLoader = () => {
    const elements = Array.from(document.querySelectorAll<BPDVideoProps>(VIDEO_HOOK));

    elements.forEach(element => {
        inviewObserver.observe(element, isVisible => {
            if (isVisible) {
                element.inViewPort = true;
                inviewObserver.unobserve(element);
            }
        });
    });
};
