import { defineElement, FC, useBindMethod, useElement, useListen, useProp } from '@atomify/hooks';
import { useStore } from '@atomify/kit';
import { hasConsent, ServiceConsentState, serviceConsentStore } from '@store/service-consent';
import { GTMPush } from '@utilities/gtm';

import VimeoVideo from '../platform/vimeo';
import YoutubeVideo from '../platform/youtube';

export type VideoPlatform = 'vimeo' | 'youtube';

type StaticVideoOptions = Omit<VideoOptions, 'player'>;

export interface BPDVideoProps extends HTMLElement {
    ready: () => void;
    playing: () => void;
    paused: () => void;
    ended: () => void;
    playVideo: () => void;
    loadVimeoVideo: (id: string) => void;
    unloadVimeoVideo: () => void;
    inViewPort: boolean;
    ignoreViewport: boolean;
    player: YoutubeVideo | VimeoVideo | null;
}

export interface VideoOptions {
    element: BPDVideoProps;
    player: HTMLDivElement;
    videoPlatform: VideoPlatform;
    videoId: string;
    videoTime: number;
    videoControls: number;
    videoMuted: number;
    videoAutoplay: number;
    videoPlaysinline: number;
    videoLoop: number;
    isPlaylist?: boolean | null;
}

const VIDEO_READY_CLASS = 'video--is-initialised';
const VIDEO_PLAYING_CLASS = 'video--is-playing';
const VIDEO_PAUSED_CLASS = 'video--is-paused';
const VIDEO_REPLAY_CLASS = 'video--is-ended';
const VIDEO_HAS_PLAYED_CLASS = 'video--has-played';

const BPDVideo: FC<BPDVideoProps> = ({ element }) => {
    const playerContainer = useElement<HTMLDivElement>('[js-hook-video-player]');
    const playButton = useElement<HTMLButtonElement>('[js-hook-video-play]');

    const [isPlaylist] = useProp<boolean | null>('isPlaylist', null);
    useProp<YoutubeVideo | VimeoVideo | null>('player', null);
    const [, , watchInViewPort] = useProp<boolean>('inViewPort', false);
    const [ignoreViewport] = useProp<boolean>('ignoreViewport', false);

    const [, subscribeConsentStore] = useStore<ServiceConsentState>(serviceConsentStore);

    useBindMethod('ready', ready);
    useBindMethod('playing', playing);
    useBindMethod('paused', paused);
    useBindMethod('ended', ended);
    useBindMethod('loadVimeoVideo', loadVimeoVideo);
    useBindMethod('unloadVimeoVideo', unloadVimeoVideo);

    const videoOptions: StaticVideoOptions = constructVideoOptions(element);

    subscribeConsentStore(({ [videoOptions.videoPlatform]: platform }) => platform && loadVideo());
    watchInViewPort(() => {
        console.log('in viewport');

        loadVideo();
    });

    async function loadVideo() {
        console.log('video loaded');
        if (!videoOptions.videoId || !hasConsent(videoOptions.videoPlatform!)) {
            return;
        }

        if (!element.inViewPort && !ignoreViewport) return;

        if (!element.player) {
            await loadPlayer();
        }

        GTMPush({
            event: 'videoLoaded',
            eventData: { ...videoOptions },
        });
    }

    function playVideo() {
        console.log('playing');

        element.player?.play();
        playing();
    }

    useListen(playButton, 'click', playVideo);

    /**
     * Called by the video loader when the video gets in view.
     * @memberof BPDVideo
     */
    async function loadPlayer() {
        if (!playerContainer.current) {
            console.error('[Video] - Unable to attach player: player container not found in DOM');
            return false;
        }

        const options = {
            player: playerContainer.current,
            ...videoOptions,
        };

        const Player = await getPlayer(videoOptions.videoPlatform);
        if (!options.videoId) return false;

        element.player = new Player(options);
        return true;
    }

    function loadVimeoVideo(videoId: string) {
        videoOptions.videoId = videoId;

        if (!element.player) {
            loadVideo();
            return;
        }

        unloadVimeoVideo();
        (element.player as VimeoVideo).loadVideo(videoId);
    }

    function unloadVimeoVideo() {
        const vimeoPlayer = element.player as VimeoVideo | null;
        if (!vimeoPlayer?.unload) return;

        reset();
        vimeoPlayer.unload();
    }

    /**
     * Called when the video platform is finished loading.
     * @memberof BPDVideo
     */
    function ready() {
        element.classList.add(VIDEO_READY_CLASS);
        element.classList.add(VIDEO_PAUSED_CLASS);
    }

    /**
     * Called when the video is starting to play
     * @memberof BPDVideo
     */
    function playing() {
        element.classList.remove(VIDEO_REPLAY_CLASS);
        element.classList.remove(VIDEO_PAUSED_CLASS);
        element.classList.add(VIDEO_PLAYING_CLASS);
        element.classList.add(VIDEO_HAS_PLAYED_CLASS);
    }

    /**
     * Called when the video is being paused
     * @memberof BPDVideo
     */
    function paused() {
        element.classList.remove(VIDEO_PLAYING_CLASS);
        element.classList.add(VIDEO_PAUSED_CLASS);
    }

    /**
     * Called when the video is ended.
     * @memberof BPDVideo
     */
    function ended() {
        element.classList.remove(VIDEO_PLAYING_CLASS);
        element.classList.add(VIDEO_REPLAY_CLASS);
    }

    /**
     * Called when the video is unloaded.
     * @memberof BPDVideo
     */
    function reset() {
        element.classList.remove(VIDEO_READY_CLASS);
        element.classList.remove(VIDEO_PLAYING_CLASS);
        element.classList.remove(VIDEO_REPLAY_CLASS);
        element.classList.remove(VIDEO_PAUSED_CLASS);
        element.classList.remove(VIDEO_HAS_PLAYED_CLASS);
    }

    async function getPlayer(platform: string): Promise<typeof YoutubeVideo | typeof VimeoVideo> {
        return new Promise(async resolve => {
            switch (platform) {
                case 'youtube':
                    const youtubePlayer = await import(
                        /* webpackChunkName: "Youtube-Player" */ '../platform/youtube'
                    );
                    resolve(youtubePlayer.default);
                    return;
                case 'vimeo':
                    const vimeoPlayer = await import(
                        /* webpackChunkName: "Vimeo-Player" */ '../platform/vimeo'
                    );
                    resolve(vimeoPlayer.default);
                    return;
            }
        });
    }

    /**
     * Construct the video options object
     * @param {NodeList} element
     * @returns {Object}
     */
    function constructVideoOptions(element: BPDVideoProps): StaticVideoOptions {
        const {
            videoPlatform,
            videoId = '',
            videoTime = '0',
            videoControls = '1',
            videoMuted = '0',
            videoAutoplay = '0',
            videoLoop = '0',
            videoPlaysinline = '0',
        } = element.dataset;

        return {
            element,
            videoId,
            isPlaylist,
            videoPlatform: videoPlatform as VideoPlatform,
            // Boolean options:
            videoTime: parseInt(videoTime, 10),
            videoControls: parseInt(videoControls, 10),
            videoMuted: parseInt(videoMuted, 10),
            videoAutoplay: parseInt(videoAutoplay, 10),
            videoPlaysinline: parseInt(videoPlaysinline, 10),
            videoLoop: parseInt(videoLoop, 10),
        };
    }
};

BPDVideo.props = {
    player: {
        type: Object,
    },
    inViewPort: {
        type: Boolean,
    },
    isPlaylist: {
        type: Boolean,
    },
    ignoreViewport: {
        type: Boolean,
    },
};

defineElement('bpd-video', BPDVideo);
