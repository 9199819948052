import { CMSClient } from '@source/services/api';

import environment from '../environment';
import { useText } from './use-text';
import { DictionaryDataInterface, TranslateOptions } from './use-translate.types';

const languageIsoCode = window.__ENVIRONMENT_DETAILS__.language || 'nl-NL';

function getTranslationByKey(dictionary: DictionaryDataInterface[], dictionaryKey: string) {
    const translations = dictionary.find(item => item.IsoCode === languageIsoCode);
    const translation = translations?.Labels.find(item => item.Key === dictionaryKey);
    return translation?.Value || '';
}

const applyTranslateOptions = (translation = '', options?: TranslateOptions) => {
    if (options?.plurality) {
        const { id, value, total } = options.plurality;
        const plurality = translation.match(
            new RegExp(
                `\\{id:\\s*${id}\\s*\\/one:([^\\/]*)\\/many:([^\\/\\}]*)(?:\\/none:([^\\/\\}]*))?(?:\\/max:([^\\}]*))?\\}`,
            ),
        );

        if (!plurality) return translation;

        let output: string;

        if (value <= 0) output = plurality[3] || plurality[2];
        else if (total && value >= total && plurality[4]) output = plurality[4];
        else if (value === 1) output = plurality[1];
        else output = plurality[2];

        translation = translation.replace(plurality[0], output.trim());
    }

    if (options?.replace) {
        const { id, value, customId } = options.replace;
        translation = translation.replace(customId ? id : new RegExp(`\\[${id}\\]`, 'g'), value);
    }

    return translation;
};

const dictionaryResolver = async () => {
    return environment.isLocal
        ? fetch('/assets/lang/index.json').then(r => r.json())
        : CMSClient.get('/Umbraco/Api/Translations/Get');
};

export async function translateItem(
    dictionaryKey: string,
    options?: TranslateOptions,
): Promise<string> {
    const dictionaryStorage = sessionStorage.getItem('dictionary');
    const dictionary =
        dictionaryStorage && dictionaryStorage !== 'null' ? JSON.parse(dictionaryStorage) : [];

    if (dictionary?.length > 0) {
        return applyTranslateOptions(getTranslationByKey(dictionary, dictionaryKey), options);
    }

    return dictionaryResolver().then(translations => {
        if (translations) {
            try {
                sessionStorage.setItem('dictionary', JSON.stringify(translations));
            } catch (error) {
                console.log('Error in sessionStorage.setItem', error);
            }

            return applyTranslateOptions(getTranslationByKey(translations, dictionaryKey), options);
        }
        return '';
    });
}

export const useTranslate = (item: string, options?: TranslateOptions) => {
    const [text, setText] = useText('');
    translateItem(item).then(result => {
        if (!result) return;

        result = applyTranslateOptions(result, options);

        setText(result);
    });
    return text;
};
