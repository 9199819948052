import {
    defineElement,
    FC,
    onDidLoad,
    useElement,
    useElements,
    useListen,
    useProp,
} from '@atomify/hooks';

const IS_HIDDEN_CLASS = 'theme-block__background-image--hidden';

export const SectionThemeElement: FC = () => {
    const [defaultTheme] = useProp<string>('defaultTheme');

    const blockElement = useElement<HTMLElement>('[js-hook-theme-block]');
    const backgroundContainer = useElement<HTMLElement>('[js-hook-theme-block-background]');
    const themeItems = useElements<HTMLElement[]>('[js-hook-theme-item]');

    let defaultBackgroundImage: HTMLImageElement | null | undefined;
    let themeBackgroundImage: HTMLImageElement | null | undefined;
    let selectedTheme: string | null;
    let selectedImage: string | null;

    useListen(themeItems, 'mouseenter', (event: MouseEvent | null) => {
        const target = event?.target as HTMLElement;

        selectedTheme = target.getAttribute('js-hook-theme-item');
        selectedImage = target.getAttribute('js-hook-image-item');
        themeBackgroundImage = backgroundContainer.current?.querySelector(
            `[js-hook-theme-block-background-image-id='${selectedImage}']`,
        );
        defaultBackgroundImage?.classList.add(IS_HIDDEN_CLASS);
        themeBackgroundImage?.classList.remove(IS_HIDDEN_CLASS);

        if (selectedTheme !== defaultTheme) {
            blockElement.current?.classList.add(`u-theme-${selectedTheme}`);
            blockElement.current?.classList.remove(`u-theme-${defaultTheme}`);
        }
    });

    useListen(themeItems, 'mouseleave', () => {
        defaultBackgroundImage?.classList.remove(IS_HIDDEN_CLASS);
        themeBackgroundImage?.classList.add(IS_HIDDEN_CLASS);

        if (selectedTheme !== defaultTheme) {
            blockElement.current?.classList.remove(`u-theme-${selectedTheme}`);
            blockElement.current?.classList.add(`u-theme-${defaultTheme}`);
        }
    });

    onDidLoad(() => {
        defaultBackgroundImage = backgroundContainer.current?.querySelector(
            `[js-hook-theme-block-background-image-default]`,
        );
    });
};

SectionThemeElement.props = {
    defaultTheme: {
        type: String,
        required: true,
        reflectToAttr: true,
    },
};

defineElement('bpd-theme-block', SectionThemeElement);
