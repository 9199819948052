import createStore from '@utilities/store';

export type SDPServiceName = 'vimeo' | 'youtube' | 'mapbox';
export type ServiceConsentState = Record<SDPServiceName, boolean>;

const UPDATE_SERVICE_CONSENT = 'updateServiceConsent';

const initialState: ServiceConsentState = {
    mapbox: false,
    vimeo: false,
    youtube: false,
};

const actions = {
    [UPDATE_SERVICE_CONSENT]: (
        state: ServiceConsentState,
        { mapbox, vimeo, youtube }: ServiceConsentState,
    ) => {
        state.mapbox = mapbox;
        state.vimeo = vimeo;
        state.youtube = youtube;
        return state;
    },
};

export const serviceConsentStore = createStore<ServiceConsentState>({
    initialState,
    actions,
});

export const updateServiceConsent = (consentState: ServiceConsentState) => {
    serviceConsentStore.dispatch(UPDATE_SERVICE_CONSENT, consentState);
};

export const hasConsent = (service: SDPServiceName) => serviceConsentStore.getState()[service];
