import { environmentDetails } from '@bpd-library/utilities';
import { modalController } from '@molecules/modal';
import { videoLoader } from '@molecules/video/utilities';
import { emailDecoder } from '@utilities/email-decoder';
import { Loadable } from '@utilities/loadable';
import { serviceConsentTracker } from '@utilities/service-consent-tracker';
import { isLocalhost, registerServiceWorker, unRegisterServiceWorker } from '@utilities/sw';

import environment from './utilities/environment';
import mockData from './utilities/environment-mock-data';

serviceConsentTracker.init();

modalController();

if (!isLocalhost) {
    unRegisterServiceWorker();
} else {
    registerServiceWorker();
}

if (environment.isLocal) {
    mockData.test();
}

environmentDetails.init({
    environment: window.__ENVIRONMENT_DETAILS__.environment,
    apiBaseUrl: window.__ENVIRONMENT_DETAILS__.apiBaseUrl,
    languageIsoCode: window.__ENVIRONMENT_DETAILS__.language,
    mapboxAccessToken: window.__ENVIRONMENT_DETAILS__.mapbox.accessToken,
    mapboxStyle: window.__ENVIRONMENT_DETAILS__.mapbox.style,
});

Loadable({
    hook: 'bpd-grid-overlay',
    loader: () => import('@molecules/grid-overlay/grid-overlay'),
});

Loadable({
    hook: 'bpd-slider',
    loader: () => import('@molecules/slider/utilities/slider'),
});

Loadable({
    hook: 'projects-overview',
    loader: () => import('@organisms/projects-overview'),
});

Loadable({
    hook: 'housing-overview',
    loader: () => import('@organisms/housing-overview'),
});

Loadable({
    hook: 'sustainability-map',
    loader: () => import('@organisms/sustainability-map'),
});

Loadable({
    hook: 'grants-overview',
    loader: () => import('@source/components/organisms/grants-overview'),
});

Loadable({
    hook: 'grants-map',
    loader: () => import('@source/components/molecules/grants-map'),
});

Loadable({
    hook: 'bpd-heatmap',
    loader: () => import('@organisms/heatmap'),
});

Loadable({
    hook: 'area-map',
    loader: () => import('@organisms/area-map'),
});

// Initialize helpers
videoLoader();
modalController();
emailDecoder();

// Atoms imports
import '@atoms';
//Molecules import
import '@molecules';
// Organisms import
import '@organisms';
